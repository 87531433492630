import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import Modal from "@material-ui/core/Modal";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import styled from "styled-components";
import { Grid } from "@material-ui/core";
import moment from "moment";
import LinearProgress from "@material-ui/core/LinearProgress";
import Box from "@material-ui/core/Box";
import Checkbox from "@material-ui/core/Checkbox";

import DragNDropInput from "./DragNDropInput";
import APIService from "../api/api";
import util from "../common/util";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 90vw;
  max-width: 720px;
  height: auto;
  background-color: white;
  border-radius: 0.3rem;
  transform: translate(-50%, -50%);
  padding: 2rem;
`;

const FlexContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  gap: 1rem;
`;

function LinearProgressWithLabel(props) {
  return (
    <Box display="flex" alignItems="center" style={{ width: "100%" }}>
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired,
};

const initialState = {
  fileName: "",
  fileDate: "",
  fileSize: "",
  signingType: "softsign",
  file: null,
  progress: 0,
};

const NewFile = ({ onClose, onSubmit }) => {
  const [state, setState] = useState(initialState);
  const [loading, setLoading] = useState(false);
  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const onChange = ({ target }) => {
    setState((oldState) => ({
      ...oldState,
      ...(target.type === "checkbox"
        ? { signingType: target.name }
        : { [target.name]: target.value }),
    }));
  };

  const onDrop = (file) => {
    if (file.length > 0) {
      const reader = new FileReader();
      reader.onerror = () => setState(initialState);
      reader.onload = () => {
        setState((oldState) => ({ ...oldState, file: reader.result }));
      };
      reader.readAsArrayBuffer(file[0]);

      setState((oldState) => ({
        ...oldState,
        fileName: file[0].name,
        fileDate: file[0].lastModified,
        fileSize: file[0].size,
        file: null,
        progress: 0,
      }));
    } else {
      setState(initialState);
    }
  };

  const submit = (e) => {
    e.preventDefault();
    setLoading(true);

    const config = {
      onUploadProgress: (progressEvent) => {
        const progress = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        if (isMounted.current) {
          setState((oldState) => ({ ...oldState, progress }));
        }
      },
    };

    const form = new FormData();
    form.append("fileName", state.fileName);
    form.append("fileDate", moment(state.fileDate).format());
    form.append("fileSize", state.fileSize);
    form.append("signingType", state.signingType);
    form.append("file", new Blob([state.file]), state.fileName);

    APIService.postFile(form, config).then((result) => {
      setLoading(false);
      if (result) onSubmit(result);
    });
  };

  return (
    <Modal open>
      <form noValidate style={loading ? { pointerEvents: "none" } : {}}>
        <Container>
          <FlexContainer>
            <Typography variant="h4" style={{ marginBottom: "1rem" }}>
              New File
            </Typography>
          </FlexContainer>

          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sm={8}
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
              }}
            >
              <TextField
                label="Name"
                name="fileName"
                variant="outlined"
                fullWidth
                autoFocus
                value={state.fileName}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  autoComplete: "off",
                  role: "presentation",
                  style: { fontSize: "16px" },
                  readOnly: true,
                }}
              />

              <TextField
                label="Date"
                name="fileDate"
                variant="outlined"
                fullWidth
                value={
                  state.fileDate
                    ? `${moment(state.fileDate).format(
                        "YYYY-MM-DD HH:mm:ss"
                      )} [${moment(state.fileDate).fromNow()}]`
                    : ""
                }
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  autoComplete: "off",
                  role: "presentation",
                  style: { fontSize: "16px" },
                  readOnly: true,
                }}
              />

              <TextField
                label="Size"
                name="fileSize"
                variant="outlined"
                fullWidth
                value={
                  state.fileSize ? util.formatBytes(state.fileSize, 2) : ""
                }
                onChange={onChange}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  autoComplete: "off",
                  role: "presentation",
                  style: { fontSize: "16px" },
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <DragNDropInput onDrop={onDrop} />
            </Grid>
          </Grid>

          <FlexContainer>
            {loading ? (
              <LinearProgressWithLabel value={state.progress} />
            ) : (
              <FlexContainer style={{ flexDirection: "column", gap: "0px" }}>
                <FlexContainer>
                  <Typography variant="body">Softsign</Typography>
                  <Checkbox
                    checked={state.signingType === "softsign"}
                    onChange={onChange}
                    name="softsign"
                    style={{ marginRight: "2rem" }}
                  />
                  <Typography variant="body">Hardsign</Typography>
                  <Checkbox
                    checked={state.signingType === "hardsign"}
                    onChange={onChange}
                    name="hardsign"
                  />
                </FlexContainer>
                <div style={{ color: "red", width: "100%" }}>
                  Only .exe, .dll, .msi, .zip or .jar files are acceptable!
                </div>
              </FlexContainer>
            )}
            <Button
              variant="contained"
              style={{ boxShadow: "none", marginLeft: "auto" }}
              onClick={onClose}
              disabled={loading}
            >
              Cancel
            </Button>

            <Button
              type="submit"
              variant="contained"
              color="primary"
              style={{ boxShadow: "none" }}
              disabled={!state.file || loading}
              onClick={submit}
            >
              Submit
            </Button>
          </FlexContainer>
        </Container>
      </form>
    </Modal>
  );
};

export default NewFile;
