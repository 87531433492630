import React, { useState } from "react";
import {
  makeStyles,
  ThemeProvider,
  createTheme,
} from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";
import { ArrowBack } from "@material-ui/icons";

import APIService from "../api/api";

const useStyles = makeStyles(() => ({
  form: {
    padding: "2rem",
    maxWidth: "46rem",
    display: "flex",
    flexDirection: "column",
    gap: "2rem",
  },
  button: {
    minWidth: "16rem",
    marginLeft: "auto",
  },
}));

const theme = createTheme({
  palette: {
    type: "dark",
    primary: { main: "#fff" },
  },
});

const ForgotPassword = ({ credentials }) => {
  const history = useHistory();
  const classes = useStyles();
  const [state, setState] = useState({
    email: process.env.REACT_APP_USER || "",
  });

  if (credentials) {
    history.push("/");
    return null;
  }

  const reset = (e) => {
    e.preventDefault();

    APIService.forgotPassword(state).then((data) => {
      if (data) {
        history.push("/reset-link-sent");
      }
    });
  };

  const validateEmail = (email) => {
    const regEx =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regEx.test(email);
  };

  const onChange = ({ target }) => {
    setState((oldState) => ({ ...oldState, [target.name]: target.value }));
  };

  return (
    <form noValidate autoComplete="off" className={classes.form}>
      <ThemeProvider theme={theme}>
        <TextField
          label="Email"
          name="email"
          variant="outlined"
          fullWidth
          autoFocus
          value={state.email}
          dark="true"
          onChange={onChange}
          InputProps={{
            autoComplete: "off",
            role: "presentation",
            style: { fontSize: "16px" },
          }}
        />

        <div style={{ display: "flex", alignItems: "center" }}>
          <div
            style={{
              color: "white",
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              gap: "0.5rem",
            }}
            onClick={() => {
              history.push("/login");
            }}
          >
            <ArrowBack />
            Back to Login
          </div>

          <Button
            disabled={!state.email || !validateEmail(state.email)}
            type="submit"
            variant="contained"
            size="large"
            className={classes.button}
            dark="true"
            style={{ fontSize: "14px" }}
            onClick={reset}
          >
            Send reset password link
          </Button>
        </div>
      </ThemeProvider>
    </form>
  );
};

export default ForgotPassword;
