import React, { useState } from "react";
import {
  makeStyles,
  ThemeProvider,
  createTheme,
} from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";

import APIService from "../api/api";

const useStyles = makeStyles(() => ({
  form: {
    padding: "2rem",
    maxWidth: "46rem",
    display: "flex",
    flexDirection: "column",
    gap: "2rem",
  },
  button: {
    minWidth: "16rem",
    marginLeft: "auto",
  },
}));

const theme = createTheme({
  palette: {
    type: "dark",
    primary: { main: "#fff" },
  },
});

const Login = ({ onSuccess, email }) => {
  const history = useHistory();
  const classes = useStyles();
  const [state, setState] = useState({
    email:
      email || localStorage.getItem("user") || process.env.REACT_APP_USER || "",
    password: process.env.REACT_APP_PASSWORD,
  });
  const credentials = JSON.parse(localStorage.getItem("credentials"));

  if (credentials) {
    history.push("/");
    return null;
  }

  const login = (e) => {
    e.preventDefault();
    APIService.login(state).then((data) => {
      if (data) {
        localStorage.setItem("credentials", JSON.stringify(data));
        localStorage.setItem("user", state.email);
        onSuccess(data);
      }
    });
  };

  const onChange = ({ target }) => {
    setState((oldState) => ({ ...oldState, [target.name]: target.value }));
  };

  return (
    <form noValidate autoComplete="off" className={classes.form}>
      <ThemeProvider theme={theme}>
        <TextField
          label="User"
          name="email"
          variant="outlined"
          fullWidth
          autoFocus
          value={state.email}
          dark="true"
          onChange={onChange}
          InputProps={{
            autoComplete: "off",
            role: "presentation",
            style: { fontSize: "16px" },
          }}
        />

        <TextField
          label="Password"
          name="password"
          type="password"
          variant="outlined"
          fullWidth
          value={state.password}
          dark="true"
          onChange={onChange}
          InputProps={{
            autoComplete: "off",
            role: "presentation",
            style: { fontSize: "16px" },
          }}
        />

        <div style={{ display: "flex", alignItems: "center" }}>
          <div
            style={{ color: "white", cursor: "pointer" }}
            onClick={() => {
              history.push("/forgot-password");
            }}
          >
            Forgot your password?
          </div>

          <Button
            disabled={!state.email || !state.password}
            type="submit"
            variant="contained"
            size="large"
            className={classes.button}
            dark="true"
            style={{ fontSize: "14px" }}
            onClick={login}
          >
            Login
          </Button>
        </div>
      </ThemeProvider>
    </form>
  );
};

export default Login;
