import React, { useState } from "react";
import Modal from "@material-ui/core/Modal";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import styled from "styled-components";

import APIService from "../api/api";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 90vw;
  max-width: 720px;
  height: auto;
  background-color: white;
  border-radius: 0.3rem;
  transform: translate(-50%, -50%);
  padding: 2rem;
`;

const FlexContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  gap: 1rem;
`;

const ChangePassword = ({ onClose, email }) => {
  const [state, setState] = useState({
    olsPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const [loading, setLoading] = useState(false);

  const onChange = ({ target }) => {
    setState((oldState) => ({ ...oldState, [target.name]: target.value }));
  };

  const submit = (e) => {
    e.preventDefault();
    setLoading(true);

    if (state.newPassword !== state.confirmPassword) {
      document.dispatchEvent(
        new CustomEvent("showMessage", {
          detail: "Confirm password doesn't match with New password!",
        })
      );
      setLoading(false);
    } else {
      APIService.changeUserPassword({
        email,
        oldPassword: state.oldPassword,
        newPassword: state.newPassword,
      }).then((result) => {
        setLoading(false);
        if (result) {
          document.dispatchEvent(
            new CustomEvent("showMessage", {
              detail: "Your password was changed!",
            })
          );
          onClose();
        }
      });
    }
  };

  return (
    <Modal open>
      <form noValidate style={loading ? { pointerEvents: "none" } : {}}>
        <Container>
          <FlexContainer>
            <Typography variant="h4" style={{ marginBottom: "1rem" }}>
              Change Password
            </Typography>
          </FlexContainer>

          <TextField
            label="Old Password"
            name="oldPassword"
            variant="outlined"
            fullWidth
            autoFocus
            value={state.oldPassword}
            onChange={onChange}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              type: "password",
              autoComplete: "off",
              role: "presentation",
              style: { fontSize: "16px" },
            }}
          />

          <TextField
            label="New Password"
            name="newPassword"
            variant="outlined"
            fullWidth
            value={state.newPassword}
            onChange={onChange}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              type: "password",
              autoComplete: "off",
              role: "presentation",
              style: { fontSize: "16px" },
            }}
          />

          <TextField
            label="Confirm New Password"
            name="confirmPassword"
            variant="outlined"
            fullWidth
            value={state.confirmPassword}
            onChange={onChange}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              type: "password",
              autoComplete: "off",
              role: "presentation",
              style: { fontSize: "16px" },
            }}
          />

          <FlexContainer style={{ marginTop: "1rem" }}>
            <Button
              variant="contained"
              style={{ boxShadow: "none", marginLeft: "auto" }}
              onClick={onClose}
              disabled={loading}
            >
              Cancel
            </Button>

            <Button
              type="submit"
              variant="contained"
              color="primary"
              style={{ boxShadow: "none" }}
              disabled={
                !state.newPassword.trim() ||
                !state.confirmPassword.trim() ||
                loading
              }
              onClick={submit}
            >
              Change Password
            </Button>
          </FlexContainer>
        </Container>
      </form>
    </Modal>
  );
};

export default ChangePassword;
