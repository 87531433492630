import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Typography from "@material-ui/core/Typography";
import Fab from "@material-ui/core/Fab";
import Chip from "@material-ui/core/Chip";
import { Add, Edit, Delete, Done } from "@material-ui/icons";
import { Grid } from "@material-ui/core";
import { useHistory } from "react-router-dom";

import APIService from "../api/api";

import NewEditUser from "./NewEditUser";
import DeleteDialog from "./DeleteDialog";

const Container = styled.div`
  display: flex;
  width: 100%;
  height: calc(100vh - 64px);
  @media (max-width: 600px) {
    height: calc(100vh - 56px);
  }
  overflow: hidden;
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  width: 100%;
  height: 100%;
  padding: 2rem;
  overflow-y: auto;
`;

const Actions = styled(Grid)`
  opacity: 0.1;
  transition: all 0.3s ease;
  text-align: end;
  @media (max-width: 600px) {
    display: none;
  }
`;

const Row = styled(Grid)`
  align-items: center;
  transition: all 0.3s ease;
  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
  &:hover ${Actions} {
    display: block;
    opacity: 1;
  }
`;

const Users = ({ credentials }) => {
  const history = useHistory();

  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [dialog, setDialog] = useState(null);

  const getUsers = () => {
    APIService.getUsers().then((data) => data && setUsers(data));
  };

  const addUser = (user) => {
    user && setUsers((oldState) => oldState.concat(user));
  };

  const updateUser = (user) => {
    user &&
      setUsers((oldState) =>
        oldState.map((row) => (row.id === user.id ? user : row))
      );
  };

  const deleteUser = () => {
    APIService.deleteUser(selectedUser.id).then((result) => {
      setDialog(null);
      result &&
        setUsers((oldState) =>
          oldState.filter((row) => row.id !== selectedUser.id)
        );
    });
  };

  const setUserStatus = (user) => {
    APIService.updateUser(user.id, {
      status: user.status === "enabled" ? "disabled" : "enabled",
    }).then(updateUser);
  };

  useEffect(() => {
    if (credentials) {
      getUsers();
    }
  }, []);

  if (!credentials) {
    history.push("/login");
    return null;
  }

  if (!credentials.adminRole) {
    history.push("/");
    return null;
  }

  return (
    <Container>
      <InnerContainer>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            sm={12}
            style={{ display: "flex", gap: "1rem", padding: "1rem 0rem" }}
          >
            <Typography
              variant="h5"
              style={{
                marginBottom: "1rem",
                marginRight: "auto",
                fontWeight: 600,
              }}
            >
              Users
            </Typography>

            <Fab
              size="small"
              style={{ boxShadow: "none" }}
              onClick={() => setDialog("create")}
            >
              <Add />
            </Fab>
          </Grid>
          <Grid item xs={12} sm={12} style={{ padding: "0rem" }}>
            <div
              style={{
                backgroundColor: "rgba(0,0,0,0.1)",
                height: "1px",
              }}
            />
          </Grid>
        </Grid>

        {users.map((user) => {
          return (
            <Row
              container
              spacing={2}
              style={{
                fontSize: "1.2rem",
                marginTop: "8px",
              }}
              key={user.id}
            >
              <Grid item xs={12} sm={3}>
                {user.lastName ? `${user.lastName}, ` : ""}
                {user.firstName}
              </Grid>
              <Grid item xs={12} sm={3}>
                {user.email}
              </Grid>
              <Grid item xs={9} sm={3}>
                {[
                  user.adminRole && "Administrator",
                  user.signerRole && "Signer",
                  user.superUser && "Super User",
                  !user.adminRole &&
                    !user.signerRole &&
                    !user.superUser &&
                    "User",
                ]
                  .filter((role) => role)
                  .join(", ")}
              </Grid>

              <Grid item xs={3} sm={1} style={{ textAlign: "right" }}>
                <Chip
                  disabled={user.email === "admin@plexxis.com"}
                  label={user.status === "enabled" ? "Active" : "Inactive"}
                  onClick={() => setUserStatus(user)}
                  clickable
                  variant={user.status === "enabled" ? "outlined" : undefined}
                  icon={user.status === "enabled" ? <Done /> : null}
                />
              </Grid>

              <Actions item xs={12} sm={2}>
                <Fab
                  disabled={user.email === "admin@plexxis.com"}
                  size="small"
                  style={{ boxShadow: "none", marginRight: "0.5rem" }}
                >
                  <Edit
                    onClick={() => {
                      setSelectedUser(user);
                      setDialog("update");
                    }}
                  />
                </Fab>

                <Fab
                  disabled={user.email === "admin@plexxis.com"}
                  size="small"
                  style={{ boxShadow: "none" }}
                >
                  <Delete
                    onClick={() => {
                      setSelectedUser(user);
                      setDialog("delete");
                    }}
                  />
                </Fab>
              </Actions>

              <Grid item xs={12} sm={12} style={{ padding: "0rem" }}>
                <div
                  style={{
                    backgroundColor: "rgba(0,0,0,0.1)",
                    height: "1px",
                  }}
                />
              </Grid>
            </Row>
          );
        })}
      </InnerContainer>

      {dialog === "create" && (
        <NewEditUser
          onClose={() => setDialog(null)}
          onSubmit={(user) => {
            addUser(user);
            setDialog(null);
          }}
        />
      )}

      {dialog === "update" && (
        <NewEditUser
          onClose={() => setDialog(null)}
          user={selectedUser}
          onSubmit={(user) => {
            updateUser(user);
            setDialog(null);
          }}
        />
      )}

      {dialog === "delete" && (
        <DeleteDialog
          title="Delete"
          message="Are you sure you want delete this user?"
          onClose={() => setDialog(null)}
          onSubmit={deleteUser}
        />
      )}
    </Container>
  );
};

export default Users;
