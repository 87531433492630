import React, { useEffect, useState } from "react";
import {
  makeStyles,
  ThemeProvider,
  createTheme as customCreateTheme,
  unstable_createMuiStrictModeTheme,
} from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import MenuIcon from "@material-ui/icons/Menu";
import CssBaseline from "@material-ui/core/CssBaseline";
import IconButton from "@material-ui/core/IconButton";
import Divider from "@material-ui/core/Divider";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import { BsShieldShaded } from "react-icons/bs";
import {
  Lock,
  SupervisedUserCircleOutlined,
  FolderOpenOutlined,
  AssignmentOutlined,
} from "@material-ui/icons";

import "./App.css";

import { ReactComponent as Plexxis } from "./assets/plexxis.svg";
import Background from "./assets/background.svg";

import Spinner from "./components/Spinner";

import Login from "./components/Login";
import Files from "./components/Files";
import Users from "./components/Users";
import Logs from "./components/Logs";
import ShowMessage from "./components/ShowMessage";
import ChangePassword from "./components/ChangePassword";
import ForgotPassword from "./components/ForgotPassword";
import ResetLinkSent from "./components/ResetLinkSent";
import ResetPassword from "./components/ResetPassword";

import APIService from "./api/api";

const createTheme =
  process.env.NODE_ENV === "production"
    ? customCreateTheme
    : unstable_createMuiStrictModeTheme;

const theme = createTheme({
  palette: {
    primary: {
      light: "#474f97",
      main: "#0e5b82",
      dark: "#000000",
      contrastText: "#fff",
    },
    secondary: {
      light: "#ffa733",
      main: "#ff9100",
      dark: "#b26500",
      contrastText: "#000",
    },
  },
});

const drawerWidth = "24rem";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(1),
  },
  title: {
    flexGrow: 1,
  },
  drawerTitle: {
    fontWeight: 100,
    marginRight: "auto",
  },
  name: {
    position: "fixed",
    bottom: "0rem",
    margin: "2rem",
  },
  toolbar: {
    padding: "0rem 1.4rem",
  },
  plexxis: {
    position: "fixed",
    bottom: "0rem",
    right: "0rem",
    width: "16rem",
    margin: "2rem",
    opacity: 1,
  },
  plexxisHidden: {
    position: "fixed",
    bottom: "0rem",
    right: "0rem",
    width: "16rem",
    margin: "2rem",
    opacity: 0.05,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerContainer: {
    overflow: "auto",
  },
  avatar: {
    marginRight: "1rem",
  },
  newRepositoryContainer: {
    padding: "1rem 0rem",
    margin: "0rem 16px",
    display: "flex",
    gap: "1rem",
    alignItems: "center",
    borderTop: "1px solid silver",
  },
}));

const App = () => {
  const classes = useStyles();
  const history = useHistory();

  const [open, setOpen] = useState(true);
  const [message, setMessage] = useState({});
  const [dialog, setDialog] = useState(null);
  const [state, setState] = useState({
    credentials: JSON.parse(localStorage.getItem("credentials")),
    dialog: null,
  });

  const { credentials } = state;

  const queryParams = window.location.hash.split("?");

  const { email } =
    queryParams.length > 1
      ? queryParams[1]
          .split("&")
          .map((v) => v.split("="))
          .reduce((pre, [key, value]) => ({ ...pre, [key]: value }), {})
      : {};

  const toggleDrawer = (value) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setOpen(value);
  };

  const logout = () => {
    APIService.logout();
    localStorage.removeItem("credentials");
    setState((oldState) => ({ ...oldState, credentials: null }));
  };

  useEffect(() => {
    if (credentials) {
      document.body.style.background = "whitesmoke";
    } else {
      document.body.style.background = `whitesmoke url(${Background}) no-repeat center center fixed`;
    }
  }, [credentials]);

  useEffect(() => {
    const showMessage = ({ detail }) => {
      if (process.env.NODE_ENV === "development") {
        console.error(detail.message);
      }

      setMessage({
        message:
          detail.response?.data?.message ||
          detail.statusText ||
          detail.message ||
          detail,
        title: detail instanceof Error ? "Error" : "Message",
      });
    };

    document.addEventListener("showMessage", showMessage);
    return () => {
      document.removeEventListener("showMessage", showMessage);
    };
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppBar position="static">
        <Toolbar className={classes.toolbar}>
          {credentials && (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer(true)}
              edge="start"
              className={classes.menuButton}
            >
              <MenuIcon fontSize="large" />
            </IconButton>
          )}

          {!credentials && (
            <BsShieldShaded
              color="#fff"
              size={40}
              style={{ marginRight: "12px" }}
            />
          )}

          <Typography variant="h5" className={classes.title}>
            Plexxis Cert Guard
          </Typography>

          {credentials && (
            <>
              <Typography variant="body1" style={{ marginRight: "1.5rem" }}>
                {credentials.name}
              </Typography>
              <Button
                variant="contained"
                size="large"
                dark="true"
                onClick={logout}
              >
                Logout
              </Button>
            </>
          )}
        </Toolbar>
      </AppBar>

      {credentials && (
        <Drawer
          className={classes.drawer}
          anchor="left"
          open={open}
          onClose={toggleDrawer(false)}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <AppBar position="static">
            <Toolbar className={classes.toolbar}>
              <BsShieldShaded
                color="#fff"
                size={40}
                style={{ marginRight: "1rem" }}
              />

              <Typography variant="h5" className={classes.drawerTitle}>
                Plexxis Cert Guard
              </Typography>
            </Toolbar>
          </AppBar>

          <div className={classes.drawerContainer}>
            <List>
              <ListItem
                button
                selected={history.location.pathname === "/"}
                onClick={() => {
                  setOpen(false);
                  history.push("/");
                }}
              >
                <FolderOpenOutlined fontSize="large" />
                <Typography
                  variant="h6"
                  style={{ fontWeight: 500, marginLeft: "1rem" }}
                >
                  Files
                </Typography>
              </ListItem>

              {credentials.adminRole && (
                <ListItem
                  button
                  selected={history.location.pathname === "/users"}
                  onClick={() => {
                    setOpen(false);
                    history.push("/users");
                  }}
                >
                  <SupervisedUserCircleOutlined fontSize="large" />

                  <Typography
                    variant="h6"
                    style={{ fontWeight: 500, marginLeft: "1rem" }}
                  >
                    Users
                  </Typography>
                </ListItem>
              )}

              {credentials.adminRole && (
                <ListItem
                  button
                  selected={history.location.pathname === "/logs"}
                  onClick={() => {
                    setOpen(false);
                    history.push("/logs");
                  }}
                >
                  <AssignmentOutlined fontSize="large" />

                  <Typography
                    variant="h6"
                    style={{ fontWeight: 500, marginLeft: "1rem" }}
                  >
                    Logs
                  </Typography>
                </ListItem>
              )}

              <Divider />

              <ListItem
                button
                onClick={() => {
                  setOpen(false);
                  setDialog("changePassword");
                }}
              >
                <Lock fontSize="large" />

                <Typography
                  variant="h6"
                  style={{ fontWeight: 500, marginLeft: "1rem" }}
                >
                  Change Password
                </Typography>
              </ListItem>
            </List>
          </div>
        </Drawer>
      )}

      <Plexxis
        className={credentials ? classes.plexxisHidden : classes.plexxis}
      />

      <React.Suspense fallback={<Spinner top={0} left={0} height="100%" />}>
        <Switch>
          <Route path="/login">
            <Login
              email={email}
              onSuccess={(newCredentials) =>
                setState((oldState) => ({
                  ...oldState,
                  credentials: newCredentials,
                }))
              }
            />
          </Route>

          <Route path="/forgot-password">
            <ForgotPassword credentials={credentials} />
          </Route>

          <Route path="/reset-link-sent">
            <ResetLinkSent credentials={credentials} />
          </Route>

          <Route path="/reset-password">
            <ResetPassword
              credentials={credentials}
              onSuccess={(newCredentials) =>
                setState((oldState) => ({
                  ...oldState,
                  credentials: newCredentials,
                }))
              }
            />
          </Route>

          <Route path="/users">
            <Users credentials={credentials} />
          </Route>

          <Route path="/logs">
            <Logs credentials={credentials} />
          </Route>

          <Route path="/">
            <Files credentials={credentials} />
          </Route>

          <Route path="*">
            <Redirect to="/" />
          </Route>
        </Switch>
      </React.Suspense>

      {message.message && (
        <ShowMessage
          title={message.title}
          message={message.message}
          onClose={() => setMessage({})}
        />
      )}

      {dialog === "changePassword" && (
        <ChangePassword
          email={credentials.email}
          onClose={() => setDialog(null)}
        />
      )}
    </ThemeProvider>
  );
};

export default App;
