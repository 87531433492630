// Credit: https://stackoverflow.com/a/18650828
const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat(`${(bytes / Math.pow(k, i)).toFixed(dm)}`) + " " + sizes[i];
};

const pallete = [
  "#000000",
  "#9D9D9D",
  "#840000",
  "#BE2633",
  "#E06F8B",
  "#493C2B",
  "#A46422",
  "#EB8931",
  "#F7E26B",
  "#2F484E",
  "#44891A",
  "#A3CE27",
  "#1B2632",
  "#005784",
  "#31A2F2",
  "#B2DCEF",
];

const getStatusColor = (status) => {
  switch (status) {
    case "queued":
      return "#d3d3d355";
    case "denied":
      return "#FF868655";
    case "authorized":
      return "#87ceeb55";
    case "signing":
      return "#ffd70055";
    case "signed":
      return "#90ee9055";

    default:
      return "lightsilver";
  }
};
const util = { formatBytes, pallete, getStatusColor };

export default util;
